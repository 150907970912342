<template>
  <div>
    <b-row class="mt-5">
      <b-col cols="3"></b-col>
      <b-col cols="6">
        <b-card class="mb-0">
          <h2>Price Management</h2>

          <b-form-group
            label="Value added tax (%)"
            invalid-feedback="vat is required."
          >
            <b-form-input
              id="name-input"
              ref="vat"
              placeholder="Enter value added tax percent here."
              required
              @focusout="CheckVat()"
              v-model="myObj.vat"
            />
          </b-form-group>
          <b-form-group
            label="Fitting room charges"
            invalid-feedback="Fitting room charges is required."
          >
            <b-form-input
              id="name-input"
              ref="marketplaceShare"
              placeholder="Enter fitting room charges here."
              required
              @focusout="CheckMarketplaceShare()"
              v-model="myObj.fittingCharges"
            />
          </b-form-group>

          <b-form-group
            label="Delivery Charges"
            invalid-feedback="Delivery charges is required."
          >
            <b-form-input
              id="name-input"
              ref="vat"
              placeholder="Enter delivery charges here."
              required
              @focusout="CheckDeliveryCharges()"
              v-model="myObj.deliveryCharges"
            />
          </b-form-group>
          <div class="text-center">
            <b-button variant="primary" @click="AddFlavour()" block>
              <span class="text-nowrap">Save</span>
            </b-button>
          </div>
        </b-card>
      </b-col>
      <b-col cols="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormFile,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter';
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';
import Ripple from 'vue-ripple-directive';
import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  components: {
    //Multiselect,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    //BTable,
    //BMedia,
    //BMediaAside,
    //BSpinner,
    //BImg,
    //BMedia,
    //BAvatar,
    //BBadge,
    //BLink,
    //BFormFile,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    // BPagination,
    //vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      loading: false,
      myfilesss: '',
      plus1: '',
      fileProfile1: '',
      logoloading1: false,
      regions: [
        {
          id: 0,
          name: '',
          image: '',
          zoneId: 0,
        },
      ],
      nextTodoId: 2,
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic',
      plus: 'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      fileProfile: '',
      logoloading: false,
      errors: {
        status: false,
        regions: false,
      },
      statusOptions: ['Active', 'Inactive'],
      filterData: '',
      date: {
        range: '',
      },
      index: null,
      cover: '',
      docs: [],
      partners: [],
      fields: [
        '#',
        { label: ' ', key: 'image' },
        { label: 'City ', key: 'city' },
        { label: 'Regions ', key: 'regionNames' },
        'actions',
      ],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        id: 0,
        vat: '',
        fittingCharges: '',
        deliveryCharges: '',
      },
      detailObj: {},
    };
  },
  methods: {
    CheckRegions() {
      if (this.regions.length == 0) {
        return (this.errors.regions = true);
      } else {
        this.regions.forEach((elem) => {
          if (
            elem.image == 0 ||
            elem.image == '' ||
            elem.image == undefined ||
            elem.name == 0 ||
            elem.name == '' ||
            elem.name == undefined
          ) {
            return (this.errors.regions = true);
          } else {
            return (this.errors.regions = false);
          }
        });
      }
    },
    repeateAgain() {
      for (let index = 0; index < this.regions.length; index++) {
        if (
          this.regions[index].image == 0 ||
          this.regions[index].image == '' ||
          this.regions[index].image == undefined ||
          this.regions[index].name == 0 ||
          this.regions[index].name == '' ||
          this.regions[index].name == undefined
        ) {
          return 0;
        }
      }
      this.regions.push({
        // id: this.nextTodoId += this.nextTodoId,
        id: 0,
        zoneId: 0,
        name: this.regions.name,
        image: this.regions.image,
      });
      console.log(this.regions);
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.regions.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    CheckCity() {
      var elem = this.$refs['cityname'];
      if (this.myObj.city.city == '' || this.myObj.city.city < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs['image'];
      if (this.myObj.city.image == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCategory() {
      console.log(this.myObj.categoryId);
      var elem = this.$refs['categoryId'];
      if (
        this.myObj.categoryId == 0 ||
        this.myObj.categoryId == undefined ||
        this.myObj.categoryId == null
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    OpenAddModal() {
      this.$bvModal.show('modal-login');
      this.myObj.city.id = 0;
      this.myObj.city.city = '';
      this.myObj.city.image = '';
      this.myObj.regions = [];
      console.log(this.myObj);
      this.logoloading = false;
      this.plus =
        'https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg';
    },
    OpenEditModal(row) {
      this.$bvModal.show('modal-login');
      this.myObj.city.id = row.id;
      this.myObj.city.city = row.city;
      this.myObj.city.image = row.image;
      this.plus = row.image;
      this.regions = row.regions;
      console.log(this.myObj);
      this.logoloading = 'loaded';
      console.log(this.plus);
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'delete',
            url: 'https://wishbox.fastech.pk/api/Zones/DeleteZone/' + id,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire('Success!', 'Zone has been deleted.', 'success').then(
                  (res) => {
                    this.LoadData();
                  },
                );
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require('axios');
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== '') {
        this.logoloading = true;
        let formData = new FormData();
        formData.append('file', this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            //
            this.myObj.city.image = this.plus;
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = 'loaded';

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    selectlogo1(e, i) {
      var myfiles = e.target.files || e.dataTransfer.files;
      console.log(myfiles[0]);
      // if (!files.length)
      //   return;

      // this.myfilesss.splice(i, 0, URL.createObjectURL(files[0]));
      // console.log(this.myfilesss)
      console.log('Hellloo');
      // return
      //this.fileProfile1 = ;
      var axios = require('axios');
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (myfiles !== '') {
        this.logoloading1 = true;
        let formData = new FormData();
        formData.append('file', myfiles[0]);
        console.log(myfiles);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus1 = fn1;
            this.regions[i].image = this.plus1;
            //
            //console.log(.this.regions);
            //console.log(this.myObj.coverImg);
            this.logoloading1 = 'loaded';

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo1(index) {
      //console.log(0);
      this.regions[index].image = '';
      this.logoloading = false;
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = '';
      this.logoloading = false;
    },
    AddFlavour() {
      //   this.CheckCity();
      //   this.CheckImage();
      //   this.CheckRegions();
      if (false) {
        return this.$bvToast.toast('Please fill the form correctly.', {
          title: 'Error!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.id !== 0) {
          this.myObj.vat = this.myObj.vat / 100;  
          console.log(this.myObj);
          var axios = require('axios');
          // this.$store.state.userData.userID
          var config = {
            method: 'put',
            url: 'https://brandaway.fastech.pk/api/PaymentControls/' + this.myObj.id,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === 'success') {
                this.$bvModal.hide('modal-login');
                console.log(response.data);
                this.LoadData();

                this.$bvToast.toast('Prices updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              } else {
                this.$bvToast.toast('Something went wrong.', {
                  title: 'Error!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          //Edit
        }
      }
    },

    LoadData() {
      this.loading = true;
      var axios = require('axios');
      var categories = {
        method: 'get',
        url: 'https://brandaway.fastech.pk/api/PaymentControls/',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(categories)
        .then((response) => {
          console.log(response.data.data);
          this.myObj = response.data.data[0];
          this.myObj.vat =   this.myObj.vat * 100;
         // this.myObj.fittingCharges =   this.myObj.marketplaceShare * 100;

          // response.data.data.forEach(elem => {
          //       elem.vat = elem.vat * 100;
          //       elem.marketplaceShare = elem.marketplaceShare * 100;
          //       elem.dcDubai = elem.dcDubai * 100;
          //       this.myObj = elem;
          //   });
          this.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$store.state.userData.userID
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
